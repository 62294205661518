import React, { useEffect, useState } from "react";
import {connect} from "react-redux";
import Wrapper from "./Wrapper";
import EntryPoint, { updateEntryPointData } from "../EntryPoint";
import { useParams } from "react-router-dom";
import { isBlank, isPresent } from "../helpers/common";
import {
  checkNpfScenarioConceptState,
  loadNpfSimulator,
  updateNpfScenarioConcept,
  updateScenarioData
} from "../store/npf_simulator_scenario/actions";
import { checkLoadingEffect } from "../helpers/callbacks_helpers";
import { NpfConfig } from "../models/forecast/npf/Config";
import { NpfScenario } from "../models/forecast/npf/Scenario";
import { NpfScenarioConcept } from "../models/forecast/npf/ScenarioConcept";
import ProductConceptTab from "./tabs/ProductConceptTab";
import CompareConceptsTab from "./tabs/CompareConceptsTab";
import ProductDetailsTab from "./tabs/ProductDetailsTab";
import ExecutionTab from "./tabs/ExecutionTab";
import { TABS } from "./helpers/helpers";
import { Loader as MainLoader } from "../common/Loader";

const ProductForecast = ({
                           npf_simulator_scenario, sidebar,
                           updateScenarioData, loadNpfSimulator, checkNpfScenarioConceptState,
                           ...props
                         }) => {
  const params = useParams()
  const { id: scenario_id } = params;
  const [config, setConfig] = useState(null)
  const [npfScenario, setScenario] = useState(null)
  const [concept, setConcept] = useState(null)
  const [tab, setTab] = useState(TABS.details)

  EntryPoint.instance.saveSearchParams()
  useEffect(() => updateEntryPointData(params, 'scenarios'), [params?.id]);
  useEffect(() => {
    if (!npf_simulator_scenario.loaded) return;
    if (isBlank(npf_simulator_scenario.scenario_id) || isBlank(scenario_id)) return;

    if (scenario_id !== npf_simulator_scenario.scenario_id) {
      const updateData = { loaded: false, scenario_loaded: false, scenario_error: false }
      updateScenarioData(updateData)
    }
  }, [scenario_id]);
  checkLoadingEffect(npf_simulator_scenario, () => loadNpfSimulator(scenario_id), { another_conditions: [scenario_id] });
  useEffect(() => {
    if (!npf_simulator_scenario.loaded) return;
    if (isPresent(npf_simulator_scenario.scenario_error)) return;
    if (npf_simulator_scenario.scenario_loading) return;

    if (isPresent(scenario_id)) {
      loadNpfSimulator(scenario_id)
    }
  }, [npf_simulator_scenario.scenario_loaded, npf_simulator_scenario.scenario_loading, scenario_id]);
  useEffect(() => {
    setConfig(
      isBlank(npf_simulator_scenario.config) ? null : new NpfConfig(npf_simulator_scenario.config)
    )
  }, [npf_simulator_scenario.config]);
  useEffect(() => {
    setScenario(
      isBlank(npf_simulator_scenario.scenario) ? null :
        new NpfScenario(npf_simulator_scenario.scenario, config)
    )
  }, [npf_simulator_scenario.scenario, config]);
  useEffect(() => {
    if (npfScenario && npf_simulator_scenario.scenario_loaded) {
      updateScenarioData({ scenario_concepts: npfScenario.concepts || [] })
    }
  }, [scenario_id, npf_simulator_scenario.scenario_loaded, npfScenario && npfScenario.id]);
  useEffect(() => {
    if (npfScenario && npf_simulator_scenario.scenario_loaded && npfScenario.selectedConceptId) {
      updateScenarioData({ concept_id: npfScenario?.selectedConceptId })
    }
  }, [npf_simulator_scenario.scenario_loaded, npfScenario && npfScenario.selectedConceptId]);
  useEffect(() => {
    updateScenarioData({ concept_id: npfScenario?.selectedConceptId || null })
  }, [npfScenario && npfScenario.selectedConceptId]);
  useEffect(() => {
    const conceptHash = npf_simulator_scenario.scenario_concepts?.find(row => row.id === npf_simulator_scenario.concept_id)
    setConcept(
      isBlank(conceptHash) ? null : new NpfScenarioConcept(conceptHash, npfScenario)
    )
  }, [npf_simulator_scenario.scenario_concepts, npf_simulator_scenario.concept_id, npfScenario]);
  useEffect(() => {
    const getParams = new URLSearchParams(document.location.search)
    const newTab = TABS[getParams.get('tab')]
    if (isPresent(newTab) && newTab !== tab) { setTab(newTab) }

    if (isPresent(getParams.get('concept_id'))) {
      // TODO: implement fetching concept_id from url query
    }
  }, [document.location.search]);
  useEffect(() => {
    if (scenario_id && concept?.isCalculating) {
      checkNpfScenarioConceptState(scenario_id, concept?.id)
    }
  }, [scenario_id, concept && concept.id, concept && concept.isCalculating])

  const opts = { config, npfScenario, concept, tab, sidebarOpen: sidebar.productForecastSidebar }

  return <Wrapper {...opts} {...props} >
    {
      npf_simulator_scenario.scenario_loaded ?
        <>
          {tab === TABS.details && <ProductDetailsTab {...opts} />}
          {tab === TABS.concepts && <ProductConceptTab {...opts} />}
          {tab === TABS.compare_concepts && <CompareConceptsTab {...opts} />}
          {tab === TABS.execution && <ExecutionTab {...opts} />}
        </> : <MainLoader />
    }
  </Wrapper>
}

const mapStateToProps = ({ sidebar, npf_simulator_scenario }) => ({ sidebar, npf_simulator_scenario });
export default connect(mapStateToProps, {
  updateScenarioData, loadNpfSimulator, updateNpfScenarioConcept, checkNpfScenarioConceptState
})(ProductForecast);
