import { isBlank, isPresent } from "../../../helpers/common";

export const CONCEPT_STATES = {
  draft: 'draft',
  calculating: 'calculating',
  completed: 'completed',
  failed: 'failed',
  deleted: 'deleted',
  freeze: 'freeze',
}

export class NpfScenarioConcept {
  constructor({ id, attributes }, scenario) {
    this.id = id;
    this.attributes = attributes;
    this.scenario = scenario;
  }

  get isCalculating() {
    return this.attributes.state === CONCEPT_STATES.calculating;
  }

  get isCompleted() {
    return this.attributes.state === CONCEPT_STATES.completed;
  }

  get isDisabled() {
    if (this.isCalculating) return true;

    return [CONCEPT_STATES.freeze, CONCEPT_STATES.deleted].includes(this.attributes.state)
  }

  get isReadyForCalculation() {
    if (this.isDisabled) return false;

    return !this.attributeFields.some(f => isBlank(this.attributesData[f.id])) &&
      this.packagesData.some(row => !this.packagesColumns.some(f =>  isBlank(row[f.id])))
  }

  get attributeFields() {
    return this.scenario.config.featuresFields.filter(f => f.isShownFor(this.scenario.cmuValues))
  }

  get packagesColumns() {
    return this.scenario.config.packagesColumns
  }

  get name() {
    return this.attributes.name;
  }

  get selectOption() {
    return { label: this.name, value: this.id }
  }

  get attributesData() {
    const viewOptions = this.scenario.conceptViewData(this);
    return {
      ...(this.attributes.attrs || {}),
      ...(viewOptions?.attributes_data || {})
    }
  }

  get packagesData() {
    const viewOptions = this.scenario.conceptViewData(this);
    return [viewOptions?.packages, this.attributes.pkg].filter(isPresent)[0] || []
  }

  get presentPackages() {
    return this.packagesData.filter(pkg =>
      !this.scenario.config?.packagesColumns?.some(f => isBlank(pkg[f.id]))
    )
  }

  get pendingPackages() {
    return this.packagesData.filter(pkg =>
      this.scenario.config?.packagesColumns?.some(f => isBlank(pkg[f.id]))
    )
  }

  valueFor(_column, _timePeriod) {
    // TODO: Implement this method
    return null;
  }
}
