import React, {useState, useRef} from "react";
import { connect } from "react-redux";
import ToggleBtn from "../filters/ToggleBtn";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import {CHART_COLORS} from "../../models/d_sight/metric_chart_helpers";
import {stringLengthLimiter} from "../../helpers/common";
import {BASE_CHART_OPTIONS} from "../../learn/details/sections/TotalDecisions";
import BaseHamburgerBtn from "../../common/BaseHamburgerBtn";
import {IconDropdown} from "../../common/dropdowns";
import Button from "react-bootstrap/Button";
import {useNavigate} from "react-router-dom";
import ExecutionTable from "../components/ExecutionTable";
import { openModal } from "../../store/modals/actions";
import { TYPE as TABLE_SETTINGS_MODAL_TYPE } from "../modals/TableSettingsModal";
import { TYPE as IMPORT_EXECUTION_PARAMS_MODAL_TYPE } from "../modals/ImportExecutionParamsModal";
import {generateExcelSuccess} from "../../helpers/alert_helpers";
import {Accordion} from "react-bootstrap";
import {useRootClose} from "react-overlays";
import {CheckForm} from "../../tree_wizard/helpers/DecisionFlowRow";
import Form from "react-bootstrap/Form";
import Switcher, {CHART_TABS, CHART_TYPES} from "../components/Switcher";
import {columnChartOptions} from "./CompareConceptsTab";

// TODO: replace FAKE_OPTIONS with real data
const FAKE_OPTIONS = [
  { label: "Value sales, USD", value: 1, concepts: [{ label: "Concept 1", value: 1 }, { label: "Concept 2", value: 2 }] },
  { label: "Unit sales", value: 3, concepts: [{ label: "Concept A", value: 3 }] },
  { label: "Incrementality to brand", value: 4, concepts: [{ label: "Idea X", value: 4 }, { label: "Idea Y", value: 5 }, { label: "Idea Z", value: 6 }] },
  { label: "Incrementality to category", value: 2, concepts: [{ label: "Concept Alpha", value: 7 }, { label: "Concept Beta", value: 8 }] },
  { label: "Media spend", value: 6, concepts: [{ label: "Concept 1", value: 9 }] },
  { label: "Distribution", value: 7, concepts: [{ label: "Vision 1", value: 10 }, { label: "Vision 2", value: 11 }] },
  { label: "Distribution2", value: 8, concepts: [{ label: "Concept 1", value: 12 }] },
  { label: "Distribution3", value: 9, concepts: [{ label: "Vision 1", value: 13 }, { label: "Vision 2", value: 14 }] },
  { label: "Distribution4", value: 10, concepts: [{ label: "Concept 1", value: 15 }] },
  { label: "Distribution5", value: 11, concepts: [{ label: "Vision 1", value: 16 }, { label: "Vision 2", value: 17 }] },
  { label: "Distribution6", value: 12, concepts: [{ label: "Vision 1", value: 18 }, { label: "Vision 2", value: 19 }] },
];
// TODO: replace FAKE_OPTIONS with real data
const FAKE_EXECUTION_OPTIONS = [
  { label: "Value sales, USD", value: 1 },
  { label: "Unit sales", value: 3 },
  { label: "Incrementality to brand", value: 4 },
  { label: "Incrementality to category", value: 2 },
  { label: "Media spend", value: 6 },
  { label: "Distribution", value: 7 },
];

export const downloadCSV = (executionTableRef) => {
  generateExcelSuccess('Your download will begin shortly.');
  executionTableRef.current.api.exportDataAsCsv();
}

const lineChartOptions = ({dataFake, series}) => ({
  ...BASE_CHART_OPTIONS,

  title: '',
  colors: CHART_COLORS,

  plotOptions: {
    column: {
      pointPadding: 0.1,
      borderWidth: 0
    }
  },

  legend: {
    itemStyle: {
      color: '#1F2F3F',
      fontWeight: '500',
      fontSize: '0.875rem'
    }
  },

  xAxis: {
    categories: dataFake.map(h => stringLengthLimiter(h.metric.name, 25)),
    crosshair: true,
    labels: {
      style: {
        fontSize: '0.75rem',
        color: '#1F2F3F'
      }
    }
  },
  yAxis: {
    title: { text: '' },
    labels: {
      format: '{text}k',
      style: {
        fontSize: '0.75rem',
        color: '#1F2F3F'
      }
    },
  },
  series
});

const ExecutionTab = ({ executionTableRef, data = [], sidebarOpen = false, openModal }) => {
  const navigate = useNavigate();
  const [selectedBenchmark, setSelectedBenchmark] = useState(FAKE_OPTIONS[0]);
  const [conceptsOptions, setConceptsOptions] = useState(selectedBenchmark?.concepts);
  const [selectedConcept, setSelectedConcept] = useState(selectedBenchmark?.concepts[0]);
  const [showExecutionMenu, setShowExecutionMenu] = useState(false);
  const [selectedActionValue, setSelectedActionValue] = useState(FAKE_EXECUTION_OPTIONS[0].value);
  const [viewTab, setViewTab] = useState(CHART_TABS.line);
  const ref = useRef();

  //TODO: replace series and dataFake with real data!
  const dataFake = [{metric: {name: 'Y1 M1'}}, {metric: {name: 'Y1 M2'}}, {metric: {name: 'Y1 M3'}}, {metric: {name: 'Y1 M4'}}]
  const series = [{
    name: 'Year 1', data: [
      {nVal: 180, nValText: "n=180", sigDown: "", sigUp: "", y: 1},
      {nVal: 180, nValText: "n=180", sigDown: "", sigUp: "", y: 2},
      {nVal: 180, nValText: "n=180", sigDown: "", sigUp: "", y: 1.1},
      {nVal: 180, nValText: "n=180", sigDown: "", sigUp: "", y: 1.3}
    ]
  }, {
    name: 'Year 2', data: [
      {nVal: 180, nValText: "n=180", sigDown: "", sigUp: "", y: 1.1},
      {nVal: 180, nValText: "n=180", sigDown: "", sigUp: "", y: 1.3},
      {nVal: 180, nValText: "n=180", sigDown: "", sigUp: "", y: 1.3},
      {nVal: 180, nValText: "n=180", sigDown: "", sigUp: "", y: 1.3}
    ]
  }]

  const iconDropdownEvents = {
    resetAllInputs: 'resetAllInputs',
    changeTableSettings: 'changeTableSettings',
    importParameters: 'importParameters',
    downloadAsCSV: 'downloadAsCSV',
  }

  const handleBenchmarkChange = (selectedOption) => {
    const selectedScenario = FAKE_OPTIONS.find((option) => option.value === selectedOption.value);
    if (selectedScenario) {
      setSelectedBenchmark(selectedScenario);
      setConceptsOptions(selectedScenario.concepts);
      setSelectedConcept(selectedScenario.concepts[0]);
    }
  };

  const maxWidthDiff = sidebarOpen ? '242px' : '72px';

  const iconDropdownSelect = (eventKey) => {
    switch (eventKey) {
      case iconDropdownEvents.resetAllInputs:
        return {}
      case iconDropdownEvents.changeTableSettings:
        return openModal({ type: TABLE_SETTINGS_MODAL_TYPE })
      case iconDropdownEvents.importParameters:
        return openModal({ type: IMPORT_EXECUTION_PARAMS_MODAL_TYPE })
      case iconDropdownEvents.downloadAsCSV:
        return downloadCSV(executionTableRef)
    }
  }
  const onRootCloseStart = () => {
    setShowExecutionMenu(false);
  };
  useRootClose(ref, onRootCloseStart, {disabled: !showExecutionMenu});

  return <div className="with-transition p-3 w-100" style={{maxWidth: `calc(100% - ${maxWidthDiff})`}}>
    <div className="d-flex align-items-end">
      <h1>Execution</h1>
    </div>
    <div className="bg-white rounded p-3 mb-2">
      <div className="d-flex position-relative pointer">
        <button className="dropdown-toggle btn px-0 h3 text-dark pt-0 h-36" onClick={() => setShowExecutionMenu(true)}>
          {FAKE_EXECUTION_OPTIONS.filter((el) => el.value === selectedActionValue)[0].label}
        </button>
        {showExecutionMenu && (
          <div ref={ref} className="dropdown-toggle-menu rounded p-3">
            <Accordion alwaysOpen>
              <h5>Metric</h5>
              <Form>
                {
                  FAKE_EXECUTION_OPTIONS.map(({ value, label, ...hash }) =>
                    <CheckForm {...hash} key={`check-form-${value}`} id={`${value}-${label}`} label={label} className="mt-2"
                               checked={selectedActionValue === value} onClick={() => setSelectedActionValue(value)}/>
                  )
                }
              </Form>
              <h5 className="mt-3">Chart type</h5>
              <div>
                <Switcher addClass={"switcher-w-100"} items={CHART_TYPES} {...{ viewTab, setViewTab }} />
              </div>
            </Accordion>
          </div>
        )}
      </div>
      {
        viewTab === CHART_TABS.line && <HighchartsReact highcharts={Highcharts} options={lineChartOptions({dataFake, series})} /> }
      {
        viewTab === CHART_TABS.column &&  <HighchartsReact highcharts={Highcharts} options={columnChartOptions({dataFake, series, measure: 'k' })} />
      }
    </div>
    <div className="d-flex flex-row justify-content-between mb-2">
      <div className="d-flex flex-row">
        <div className="d-flex me-2">
          <div className="d-inline-flex my-auto me-1">Benchmark:</div>
          <ToggleBtn
            options={FAKE_OPTIONS}
            currentOption={selectedBenchmark}
            onSelect={(value) => handleBenchmarkChange(value)}
            showInput={true}
          />
        </div>
        <ToggleBtn
          options={conceptsOptions}
          currentOption={selectedConcept}
          onSelect={(value) => console.log("Selected Concept value:", value)}
        />
      </div>
      <div className={`buttons-container d-flex justify-content-end ms-auto`}>
        <Button onClick={() => {}}
                className="me-2 light-gray-disabled"
                disabled={false} disabled>Run model</Button>
        <Button onClick={() => {navigate('edit_launch')}}
                className="btn-secondary me-2"
                disabled={false}>Edit launch parameters</Button>
        <div className="d-flex">
          <IconDropdown id="Benchmark_Actions" bsPrefix="h5 m-0 btn-link pt-2"
                        className="btn btn-secondary btn-lg-round d-flex p-0 float-end"
                        onSelect={iconDropdownSelect}>
            <BaseHamburgerBtn eventKey={iconDropdownEvents.resetInputsHandler} title="Reset all inputs to defaults" />
            <BaseHamburgerBtn eventKey={iconDropdownEvents.changeTableSettings} title="Change table settings" />
            <BaseHamburgerBtn eventKey={iconDropdownEvents.importParameters} title="Import execution parameters" />
            <BaseHamburgerBtn eventKey={iconDropdownEvents.downloadAsCSV} title="Download as CSV"/>
          </IconDropdown>
        </div>
      </div>
    </div>
    <ExecutionTable executionTableRef={executionTableRef}/>
  </div>
}
export default connect(null, { openModal })(ExecutionTab)