import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {baseCompOptions} from "../../dsight/analysis/analysis_metrics/charts/brand_funnel/helpers";
import {CHART_COLORS} from "../../models/d_sight/metric_chart_helpers";
import ToggleBtn from "../filters/ToggleBtn";
import {Table} from "react-bootstrap";
import {stringLengthLimiter} from "../../helpers/common";
import {chartYAxis} from "../../helpers/metric_helpers";

// TODO: replace FAKE_OPTIONS with real data
const FAKE_OPTIONS = [
  { label: "Value sales, USD", value: 1, concepts: [{ label: "Concept 1", id: 1 }, { label: "Concept 2", id: 2 }] },
  { label: "Unit sales", value: 3, concepts: [{ label: "Concept A", id: 3 }] },
  { label: "Incrementality to brand", value: 4, concepts: [{ label: "Idea X", id: 4 }, { label: "Idea Y", id: 5 }, { label: "Idea Z", id: 6 }] },
  { label: "Incrementality to category", value: 2, concepts: [{ label: "Concept Alpha", id: 7 }, { label: "Concept Beta", id: 8 }] },
  { label: "Media spend", value: 6, concepts: [{ label: "Concept 1", id: 9 }] },
  { label: "Distribution", value: 7, concepts: [{ label: "Vision 1", id: 10 }, { label: "Vision 2", id: 11 }] },
  { label: "Distribution2", value: 8, concepts: [{ label: "Concept 1", id: 12 }] },
  { label: "Distribution3", value: 9, concepts: [{ label: "Vision 1", id: 13 }, { label: "Vision 2", id: 14 }] },
  { label: "Distribution4", value: 10, concepts: [{ label: "Concept 1", id: 15 }] },
  { label: "Distribution5", value: 11, concepts: [{ label: "Vision 1", id: 16 }, { label: "Vision 2", id: 17 }] },
  { label: "Distribution6", value: 12, concepts: [{ label: "Vision 1", id: 18 }, { label: "Vision 2", id: 19 }] },
];

export const columnChartOptions = ({dataFake, series, measure = 'M'}) => ({
  ...baseCompOptions({ measure: measure }),

  colors: CHART_COLORS,

  plotOptions: {
    column: {
      pointPadding: 0.1,
      borderWidth: 0
    },
    series: {
      borderWidth: 0,
      dataLabels: {
        enabled: true,
        format: `{point.y}${measure}`
      }
    }
  },

  xAxis: {
    categories: dataFake.map(h => stringLengthLimiter(h.metric.name, 25)),
    crosshair: true,
    labels: {
      style: {
        fontSize: '0.75rem',
        color: '#1F2F3F'
      }
    }
  },
  ...chartYAxis({measure: measure}),

  series
});

export default ({data = [], sidebarOpen = false}) => {
  //TODO: replace series and dataFake with real data!
  const dataFake = [{metric: {name: 'concept 1'}}, {metric: {name: 'concept 2'}}, {metric: {name: 'concept 3'}}, {metric: {name: 'concept 4'}}]
  const series = [{
    name: 'Year 1', data: [
      {nVal: 180, nValText: "n=180", sigDown: "", sigUp: "", y: 5},
      {nVal: 180, nValText: "n=180", sigDown: "", sigUp: "", y: 3},
      {nVal: 180, nValText: "n=180", sigDown: "", sigUp: "", y: 6},
      {nVal: 180, nValText: "n=180", sigDown: "", sigUp: "", y: 4}
    ]
  }, {
    name: 'Year 2', data: [
      {nVal: 180, nValText: "n=180", sigDown: "", sigUp: "", y: 5},
      {nVal: 180, nValText: "n=180", sigDown: "", sigUp: "", y: 2},
      {nVal: 180, nValText: "n=180", sigDown: "", sigUp: "", y: 5},
      {nVal: 180, nValText: "n=180", sigDown: "", sigUp: "", y: 3}
    ]
  }]

  const maxWidthDiff = sidebarOpen ? '242px' : '72px';

  return <div className="with-transition p-3 w-100" style={{maxWidth: `calc(100% - ${maxWidthDiff})`}}>
    <div className="d-flex align-items-end">
      <h1>Compare concepts</h1>
    </div>
    <div className="bg-white rounded mb-3 p-3">
      <ToggleBtn
        options={FAKE_OPTIONS}
        currentOption={FAKE_OPTIONS[0]}
        onSelect={(id) => console.log("Selected concept ID:", id)}
        labelClassName={'h3 text-dark pt-0 h-36'}
      />
      <HighchartsReact highcharts={Highcharts} options={columnChartOptions({dataFake, series})} />
    </div>
    <div className="bg-white rounded mb-3 p-2 overflow-scroll">
      <Table borderless className="bubbles-table mb-0">
        <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col" className="text-end">Product concept</th>
          {/* NOTE!!! we may map here with Concepts. Every child tag of TH or TD will display with ellipsis >> <th><span>....</span></th>*/}
          {/* NOTE!!! to have NO elipsis >> <th>...text</th>*/}
          <th scope="col" className="fw-normal"><span>Concept 1</span></th>
          <th scope="col" className="fw-normal">Concept 2</th>
          <th scope="col" className="fw-normal">Concept 3</th>
          <th scope="col" className="fw-normal">Concept 4</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td rowSpan="3">Value sales, USD</td>
          <td>Total</td>
          <td>$57.5M</td>
          <td>$XX.XM</td>
          <td>$XX.XM</td>
          <td>$XX.XM</td>
        </tr>
        <tr>
          <td>Year 1</td>
          <td>$25.0M</td>
          <td>$XX.XM</td>
          <td>$XX.XM</td>
          <td>$XX.XM</td>
        </tr>
        <tr>
          <td>Year 2</td>
          <td>$32.5M</td>
          <td>$XX.XM</td>
          <td>$XX.XM</td>
          <td>$XX.XM</td>
        </tr>

        <tr>
          <td colSpan="2"><span>Incrementality to brand</span></td>
          <td>20%</td>
          <td>25%</td>
          <td>18%</td>
          <td>XX%</td>
        </tr>
        </tbody>
      </Table>
    </div>
  </div>
}