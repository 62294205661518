import React, {Fragment} from "react";
import {connect} from "react-redux";
import AlertsSection from "../alerts";
import Header from "./header";
import { setProductForecastSidebarOpen } from "../store/sidebar/actions";
import NavigationItems from "./components/NavigationItems";
import {
  EditProductScenarioModal,
  showEditProductCategoryScenarioModal
} from "./modals/EditProductScenarioModal";
import { closeModal } from "../store/modals/actions";
import CopyProductScenarioModal, { showCopyProductCategoryScenarioModal } from "./modals/CopyProductScenarioModal";
import { updateNpfScenario } from "../store/npf_simulator_scenario/actions";
import { resetForecastData } from "../store/homepage/actions";

import TableSettingsModal, { showTableSettingsModal } from "./modals/TableSettingsModal";
import ImportExecutionParamsModal, { showImportExecutionParamsModal } from "./modals/ImportExecutionParamsModal";
import ImportConceptsModal, { showImportConceptsModal } from "./modals/ImportConceptsModal";

import CopyScenarioConceptModal, { showCopyScenarioConceptModal } from "./modals/CopyScenarioConceptModal";
import { EditScenarioConceptModal, showEditScenarioConceptModal } from "./modals/EditScenarioConceptModal";
import CreateScenarioConceptModal, { showCreateScenarioConceptModal } from "./modals/CreateScenarioConceptModal";

const Wrapper = ({
                   modal, closeModal,
                   children, activeTab,
                   sidebar,
                   ...opts
                 }) =>
  <Fragment>
    <AlertsSection/>
    <Header {...opts}/>
    <div className="d-flex vertical-tree-container">
      <div className={`forecast-sidebar bg-light-gray d-block position-relative ${sidebar.productForecastSidebar ? 'open' : ''}`}
           id="sidebar-navigation">
        <NavigationItems {...{sidebar}} {...opts} />
      </div>
      {children}
    </div>
    <div className="modals">
      <EditProductScenarioModal key={`edit-product-scenario-modal-${modal.slug}`} show={showEditProductCategoryScenarioModal(modal)} onClose={closeModal} {...opts} />
      <CopyProductScenarioModal key={`copy-product-scenario-modal-${modal.slug}`} show={showCopyProductCategoryScenarioModal(modal)} onClose={closeModal} {...opts} />

      <ImportConceptsModal key={`import-concepts-modal-${modal.slug}`} show={showImportConceptsModal(modal)} onClose={closeModal} />
      <TableSettingsModal key={`table-settings-modal-${modal.slug}`} show={showTableSettingsModal(modal)} onClose={closeModal} />
      <ImportExecutionParamsModal key={`import-execution-params-modal-${modal.slug}`} show={showImportExecutionParamsModal(modal)} onClose={closeModal} />

      <EditScenarioConceptModal key={`edit-product-scenario-concept-modal-${modal.slug}`} show={showEditScenarioConceptModal(modal)} onClose={closeModal} {...opts} />
      <CopyScenarioConceptModal key={`copy-product-scenario-concept-modal-${modal.slug}`} show={showCopyScenarioConceptModal(modal)} onClose={closeModal} {...opts} />
      <CreateScenarioConceptModal key={`create-product-scenario-concept-modal-${modal.slug}`} show={showCreateScenarioConceptModal(modal)} onClose={closeModal} {...opts} />
    </div>
  </Fragment>

const mapStateToProps = ({ sidebar, current_user, modal, npf_simulator_scenario }) => ({
  sidebar, current_user, modal, npf_simulator_scenario
});

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(closeModal()),
  resetForecastData: () => dispatch(resetForecastData()),
  updateNpfScenario: (scenario_id, updateData, callback) => dispatch(updateNpfScenario(scenario_id, updateData, callback)),
  setProductForecastSidebarOpen: (val) => dispatch(setProductForecastSidebarOpen(val)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Wrapper)
