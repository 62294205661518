import React, { useMemo } from "react";
import {Link} from "react-router-dom";
import { NpfScenarioConcept } from "../../models/forecast/npf/ScenarioConcept";
import { deepEqual } from "../../helpers/store_helpers";
import { isBlank } from "../../helpers/common";

const ConceptVariation = ({
                            concept, conceptVariant, attributeFields,
                            matchAttributes = () => {}
                          }) => {
  const eqlAttrs = deepEqual(concept.attributesData, conceptVariant.attributesData)
  return <div className="mb-3">
    {
      eqlAttrs ?
        <>
          <h4>{conceptVariant.name}</h4>
          <div className="mt-2 text-muted">
            No variance
          </div>
        </> :
        <>
          <div className="d-flex justify-content-between">
            <h4 className="mb-0">{conceptVariant.name}</h4>
            {
              concept.isDisabled ? null :
                <Link to={'#'} onClick={(e) => matchAttributes(e, concept, conceptVariant)} >
                  Match concept
                </Link>
            }
          </div>
          {
            attributeFields.map(field => {
              if (isBlank(concept.attributesData[field.id]) || isBlank(conceptVariant.attributesData[field.id])) {
                return null
              }
              if(deepEqual(concept.attributesData[field.id], conceptVariant.attributesData[field.id])) {
                return null
              };
              if (field.isSingleSelect) {
                return <div className="mt-2" key={`attributes-variant-${concept.id}-${conceptVariant.id}-${field.id}`}>
                  {field.displayName}: <span className="fw-bolder">{field.values[conceptVariant.attributesData[field.id]]}</span>
                </div>
              } else if (field.isMultiSelect) {
                return <div className="mt-2" key={`attributes-variant-${concept.id}-${conceptVariant.id}-${field.id}`}>
                  {field.displayName}:
                  <span className="fw-bolder">
                    { conceptVariant.attributesData[field.id].map(optionId => field.values[optionId]).join(', ') }
                  </span>
                </div>
              }
            })
          }
        </>
    }
  </div>
}

export default  ({
                   config, concept, npfScenario, npf_simulator_scenario,
                   matchAttributes = () => {}
                 }) => {
  const anotherConcepts = useMemo(() =>
    npf_simulator_scenario.scenario_concepts
      ?.filter(c => c.id !== concept?.id)
      ?.map(concept => new NpfScenarioConcept(concept, npfScenario)) || []
    , [npf_simulator_scenario.scenario_concepts, concept])

  const attributeFields = useMemo(() =>
    config.featuresFields.filter(f => f.isShownFor(npfScenario.cmuValues))
  , [config, npfScenario])

  return <>
  {
    concept && anotherConcepts.map(conceptVariant =>
      <ConceptVariation key={`attributes-variant-${concept.id}-${conceptVariant.id}`}
                        {...{ concept, conceptVariant, attributeFields, matchAttributes }} />
    )
  }
  </>
}
