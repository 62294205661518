import React, {useMemo, useState} from "react";
import {connect} from "react-redux";
import Header from "./header";
import AlertsSection from "../../alerts";
import ToggleBtn from "../filters/ToggleBtn";
import CurrencyInput from "react-currency-input-field";
import Switcher, {LAUNCH_INPUT_TABS, LAUNCH_INPUT_TYPES} from "../components/Switcher";
import InputRow from "./components/InputRow";

// TODO: replace productScenariosOptions with real data
export const productScenariosOptions = [
  { display_name: 'Import values', label: 'Import values', value: 0 },
  { display_name: "GoodBar Q4", label: "GoodBar Q4", value: 1, concepts: [{ label: "Concept 1", value: 1 }, { label: "Concept 2", value: 2 }] },
  { display_name: "DailyBrew H1", value: 3, concepts: [{ label: "Concept A", value: 3 }] },
  { display_name: "DailyBrew H1", label: "NutriCrunch Growth", value: 4, concepts: [{ label: "Idea X", value: 4 }, { label: "Idea Y", value: 5 }, { label: "Idea Z", value: 6 }] },
  { display_name: "PureWash Launch", label: "PureWash Launch", value: 2, concepts: [{ label: "Concept Alpha", value: 7 }, { label: "Concept Beta", value: 8 }] },
  { display_name: "Essentials Line FY25", label: "Essentials Line FY25", value: 6, concepts: [{ label: "Concept 1", value: 9 }] },
  { display_name: "ShineCare Pilot 1", label: "ShineCare Pilot 1", value: 7, concepts: [{ label: "Vision 1", value: 10 }, { label: "Vision 2", value: 11 }] },
  { display_name: "SnackWell Expansion", label: "SnackWell Expansion", value: 8, concepts: [{ label: "Concept 1", value: 12 }] },
  { display_name: "PureBubbles Q2", label: "PureBubbles Q2", value: 9, concepts: [{ label: "Vision 1", value: 13 }, { label: "Vision 2", value: 14 }] },
  { display_name: "Fuel Line Regional", label: "Fuel Line Regional", value: 10, concepts: [{ label: "Concept 1", value: 15 }] },
  { display_name: "ShineCare Pilot FY50", label: "ShineCare Pilot FY50", value: 11, concepts: [{ label: "Vision 1", value: 16 }, { label: "Vision 2", value: 17 }] },
  { display_name: "PureBubbles Q4", label: "PureBubbles Q4", value: 12, concepts: [{ label: "Vision 1", value: 18 }, { label: "Vision 2", value: 19 }] },
];

const EditLaunch = ({ ...opts }) => {
  const [selectedScenario, setSelectedScenario] = useState(productScenariosOptions[0]);
  const [viewTab, setViewTab] = useState(LAUNCH_INPUT_TABS.national)
  const handleScenarioChange = (selectedOption) => {
    const selectedScenario = productScenariosOptions.find((option) => option.value === selectedOption.value);
    if (selectedScenario) {
      setSelectedScenario(selectedScenario);
    }
  };

  return <>
    <AlertsSection/>
    <Header {...opts}/>
    <div className="launch-container container-xl mt-3">
      <div className="d-flex justify-content-between">
        <h1>
          Launch parameters
        </h1>
        <div className="d-flex flex-row">
          <div className="d-flex flex-row me-2">
            <ToggleBtn
              options={productScenariosOptions}
              currentOption={selectedScenario}
              onSelect={(value) => handleScenarioChange(value)}
              showInput={true}
            />
          </div>
        </div>
      </div>
      <div className="bg-white rounded mb-3 p-3">
        <div className="d-flex flex-row gap-2">
          <div style={{width: 360}}>
            <h3>Marketing spend</h3>
            <CurrencyInput
              className="form-control rounded"
              id={`price.id-0`}
              name={`price.id-0`}
              placeholder={'Enter the price'}
              decimalsLimit={2}
              prefix={'$'}
              allowNegativeValue={false}
              maxLength={20}
              decimalScale={2}
              value={0}
              onValueChange={() => {}}
            />
          </div>
          <div style={{width: 360}}>
            <h3>Input type</h3>
            <div style={{width: 360}}>
              <Switcher addClass={"switcher-w-100"} items={LAUNCH_INPUT_TYPES} {...{ viewTab, setViewTab }} />
            </div>
          </div>
        </div>
      </div>
      {viewTab === LAUNCH_INPUT_TABS.national && <InputRow />}
      {viewTab === LAUNCH_INPUT_TABS.accounts && <InputRow isAccount={true} id={1} />}
    </div>
  </>
}

const mapStateToProps = ({ currentUser, current_org }) => ({ currentUser, current_org });
export default connect(mapStateToProps, {})(EditLaunch)