import React, {useMemo} from "react";
import TabComponent from "./TabComponent";
import { TABS } from "../helpers/helpers";
import { useNavigate } from "react-router-dom";
import Alerts from "../../../js/alerts";
import { NpfScenarioConcept } from "../../models/forecast/npf/ScenarioConcept";
import {connect} from "react-redux";

const CompleteConcepstErrorAlert = () => Alerts.error({
  title: 'Sorry, more information is needed. Please complete all concept attributes, packaging, and run the model before viewing this page.',
})

const NavigationItems = ({ sidebar, setProductForecastSidebarOpen, tab, npfScenario, npf_simulator_scenario }) => {
  const navigate = useNavigate()
  const anyCompletedConceptRecord = useMemo(() =>
      npf_simulator_scenario.scenario_concepts?.find(concept => new NpfScenarioConcept(concept, npfScenario).isCompleted) || false
    , [npf_simulator_scenario.scenario_concepts, npfScenario])
  const isOpened = sidebar.productForecastSidebar;
  const setTab = (tab) => () => {
    const getParams = new URLSearchParams(document.location.search)
    getParams.set('tab', tab)
    navigate(`/npf_simulator/scenarios/${npfScenario.local_id}?${getParams.toString()}`)
  }

  const onClickTab = (tabTitle) => anyCompletedConceptRecord ? setTab(tabTitle) : CompleteConcepstErrorAlert

  return <div className="sidebar-top">
    <button onClick={() => setProductForecastSidebarOpen(!isOpened)}
            className={`btn-rotate bg-light btn-xsm-round d-flex justify-center align-center border-light-gray mt-2
             ${isOpened ? 'rotate-svg-180' : 'rotate-svg-0'}`}>
      <i className="fas fa-chevron-right fa-lg text-primary"/>
    </button>
    <ul className={`list-unstyled m-0 with-transition ${isOpened ? 'p-3' : 'px-2 py-3'}`}>
      <li className={tab === TABS.details ? 'active' : ''} >
        <TabComponent id={1} title={'Details'} icon={'fa-circle-info'} onClick={setTab(TABS.details)} {...{isOpened}}/>
      </li>
      <li className={tab === TABS.concepts ? 'active' : ''}>
        <TabComponent id={2} title={'Product concept'} icon={'fa-box'} onClick={setTab(TABS.concepts)} {...{isOpened}}/>
      </li>
      <li className={tab === TABS.compare_concepts ? 'active' : ''}>
        <TabComponent id={3} title={'Compare concepts'} icon={'fa-down-left-and-up-right-to-center'} onClick={onClickTab(TABS.compare_concepts)} {...{isOpened}}/>
      </li>
      <li className={tab === TABS.execution ? 'active' : ''}>
        <TabComponent id={4} title={'Execution'} icon={'fa-rocket'} onClick={onClickTab(TABS.execution)} {...{isOpened}}/>
      </li>
    </ul>
  </div>
}

const mapStateToProps = ({ npf_simulator_scenario }) => ({
  npf_simulator_scenario
});
export default connect(mapStateToProps, {})(NavigationItems)
