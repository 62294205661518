import React, { useMemo } from "react";
import {Link} from "react-router-dom";
import { NpfScenarioConcept } from "../../models/forecast/npf/ScenarioConcept";
import { deepEqual } from "../../helpers/store_helpers";

const ConceptVariation = ({
                            concept, conceptVariant, pkfFields,
                            matchPackages = () => {}
                          }) => {
  const eqlAttrs = deepEqual(concept.presentPackages, conceptVariant.presentPackages)
  const pkgsToAdd = conceptVariant.presentPackages.filter(pkg =>
    !concept.presentPackages.some(p => deepEqual(p, pkg))
  )
  const pkgsToRemove = concept.presentPackages.filter(pkg =>
    !conceptVariant.presentPackages.some(p => deepEqual(p, pkg))
  )

  return <div className="mb-3">
    {
      eqlAttrs ?
        <>
          <h4>{conceptVariant.name}</h4>
          <div className="mt-2 text-muted">
            No variance
          </div>
        </> :
        <>
          <div className="d-flex justify-content-between">
            <h4 className="mb-0">{conceptVariant.name}</h4>
            {
              concept.isDisabled ? null :
                <Link to={'#'} onClick={(e) => matchPackages(e, concept, conceptVariant)} >
                  Match concept
                </Link>
            }
          </div>
          <div>
            {
              pkgsToRemove.map((pgsData, index) =>
                <div className="d-flex align-middle gap-1" key={`packages-variant-${concept.id}-${conceptVariant.id}-missed-${index}`}>
                  <i className="fas fa-circle-minus text-danger concept-icon"/>
                  <span className="text-decoration-line-through">
                    { pkfFields.map(f => f.formatForList(pgsData[f.id])).join('') }
                  </span>
                </div>
              )
            }
            {
              pkgsToAdd.map((pgsData, index) =>
                <div className="d-flex align-middle gap-1 mt-1" key={`packages-variant-${concept.id}-${conceptVariant.id}-add-${index}`}>
                  <i className="fas fa-circle-plus text-success concept-icon"/>
                  <span>
                    { pkfFields.map(f => f.formatForList(pgsData[f.id])).join('') }
                  </span>
                </div>
              )
            }
          </div>
        </>
    }
  </div>
}

export default  ({
                   config, concept, npfScenario, npf_simulator_scenario,
                   matchPackages = () => {}
                 }) => {
  const anotherConcepts = useMemo(() =>
    npf_simulator_scenario.scenario_concepts
      ?.filter(c => c.id !== concept?.id)
      ?.map(concept => new NpfScenarioConcept(concept, npfScenario)) || []
    , [npf_simulator_scenario.scenario_concepts, concept])

  const pkfFields = useMemo(() =>
      config.packagesColumns
  , [config])

  return <>
  {
    concept && anotherConcepts.map(conceptVariant =>
      <ConceptVariation key={`packages-variant-${concept.id}-${conceptVariant.id}`}
                        {...{ concept, conceptVariant, pkfFields, matchPackages }} />
    )
  }
  </>
}
