import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import CloseIcon from "../../common/CloseIcon";
import { ModalDoneFooterP0 } from "../../common/modals";
import CharCounter from "../../common/CharCounter";
import { DISPLAY_NAME_LIMIT } from "../../models/forecast/ForecastScenario";
import { isBlank } from "../../helpers/common";
import { NpfScenarioConcept } from "../../models/forecast/npf/ScenarioConcept";
import { useNavigate } from "react-router-dom";
import { createNpfSimulatorRecord } from "../../store/homepage/actions";

export const showCopyProductCategoryScenarioModal = (modal) => modal.type === "CopyProductCategoryScenarioModal" && modal.shown;

const CopyProductScenarioModal = ({
                                    show, onClose,
                                    npf_simulator_scenario, config, npfScenario,
                                    createNpfSimulatorRecord
                                  }) => {
  const navigate = useNavigate()
  const focusElement = React.useRef(null);
  const [displayName, setDisplayName] = useState('');
  const [showChar, setShowChar] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [errors, setErrors] = useState({})
  const [selectedConcepts, setSelectedConcepts] = useState([]);

  useEffect(() => {
    if (show && focusElement.current) {
      focusElement.current.focus();
    }
  }, [show, focusElement]);

  useEffect(() => {
    if (show) {
      setDisplayName(`${npfScenario?.displayName} (copy)`);
      setSelectedConcepts([]);
    }
  }, [show]);

  const handleConceptChange = (value) => {
    setSelectedConcepts(prev => {
      if (prev.includes(value)) {
        return prev.filter(concept => concept !== value);
      } else {
        return [...prev, value];
      }
    });
  };

  const onSubmit = () => {
    setSubmit(true);
    setTimeout(() => {
      setSubmit(false);
      onClose();
    }, 500);

    createNpfSimulatorRecord(config.id, {
      scenario: {
        display_name: displayName.trim(),
        selected_concepts: selectedConcepts,
        copy_from_id: npf_simulator_scenario.scenario_id
      }
    }, (scenario, errors) => {
      setSubmit(false);
      if (scenario) {
        setTimeout(() => {
          navigate(`/npf_simulator/scenarios/${scenario.id}`)
        }, 100)
        onClose()
      } else {
        setErrors(errors)
      }
    })

  };

  return (
    <Modal backdrop="static" size="md" show={show} onHide={onClose}>
      <Modal.Body>
        <CloseIcon onClose={onClose} />
        <h2 className="mb-2">Save as a new scenario</h2>
        <div className="mb-3">
          <h3 className="mb-2">Scenario name</h3>
          <CharCounter show={showChar} field={displayName} limit={DISPLAY_NAME_LIMIT} id="concept-name-counter"/>
          <input
            className={`form-control`}
            placeholder="Enter the name of concept"
            ref={focusElement}
            value={displayName}
            autoFocus={show}
            disabled={submit}
            maxLength={DISPLAY_NAME_LIMIT}
            onChange={(e) => setDisplayName(e.target.value)}
            onBlur={() => setShowChar(false)}
            onFocus={() => setShowChar(true)}
          />
        </div>
        {
          (npf_simulator_scenario.scenario_concepts?.length > 0) &&
            <div className="mb-3">
              <h3 className="mb-2">Product concepts</h3>
              <div className="d-flex flex-column gap-2">
                {npf_simulator_scenario.scenario_concepts?.map((concept) => {
                  const $concept = new NpfScenarioConcept(concept, npfScenario)
                  return (
                    <div key={$concept.id} className="form-check mb-0">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id={$concept.id}
                        checked={selectedConcepts.includes($concept.id)}
                        onChange={() => handleConceptChange($concept.id)}
                        disabled={submit}
                      />
                      <label className="form-check-label" htmlFor={$concept.id}>
                        {$concept.name}
                      </label>
                    </div>
                  )
                })}
              </div>
            </div>
        }

      </Modal.Body>
      <ModalDoneFooterP0 onClose={onSubmit} disabled={submit || isBlank(displayName?.trim())}
      />
    </Modal>
  );
};


const mapStateToProps = ({ npf_simulator_scenario }) => ({ npf_simulator_scenario });
export default connect(mapStateToProps, { createNpfSimulatorRecord })(CopyProductScenarioModal);
