import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import CloseIcon from '../../common/CloseIcon';
import CharCounter from "../../common/CharCounter";
import { DISPLAY_NAME_LIMIT } from "../../models/forecast/ForecastScenario";
import { ModalDoneFooterP0 } from "../../common/modals";
import { isBlank } from "../../helpers/common";

export const showEditScenarioConceptModal = (modal) =>
  modal.shown && modal.type === 'EditScenarioConceptModal';

export const EditScenarioConceptModal = ({
                                           show, onClose,
                                           npfScenario, concept,
                                           updateNpfScenarioConcept
                                         }) => {
  const focusElement = React.useRef(null);
  const [displayName, setDisplayName] = useState(concept?.name || '');
  const [showChar, setShowChar] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [errors, setErrors] = useState({})

  useEffect(() => {
    if (show && focusElement.current) {
      focusElement.current.focus();
    }
  }, [show, focusElement]);

  useEffect(() => {
    if (show) {
      setDisplayName(concept?.name);
    }
  }, [show]);

  const onSubmit = () => {
    setSubmit(true);

    updateNpfScenarioConcept(npfScenario.local_id, concept.id, {
      concept: { name: displayName.trim() }
    }, (concept, errors) => {
      setSubmit(false);
      if (concept) {
        onClose()
      } else {
        setErrors(errors)
      }
    })
  };

  return (
    <Modal backdrop="static" size="md" show={show} onHide={onClose}>
      <Modal.Body>
        <CloseIcon onClose={onClose} />
        <h2 className="mb-2">Edit concept</h2>
        <div className="mb-3">
          <h3 className="mb-2">Concept name</h3>
          <CharCounter show={showChar} field={displayName} limit={DISPLAY_NAME_LIMIT} id="concept-name-counter"/>
          <input
            className={`form-control`}
            placeholder="Enter the name of concept"
            ref={focusElement}
            value={displayName}
            autoFocus={show}
            disabled={submit}
            maxLength={DISPLAY_NAME_LIMIT}
            onChange={(e) => setDisplayName(e.target.value)}
            onBlur={() => setShowChar(false)}
            onFocus={() => setShowChar(true)}
          />
        </div>
      </Modal.Body>
      <ModalDoneFooterP0 onClose={onSubmit} disabled={submit || isBlank(displayName?.trim())} />
    </Modal>
  );
};
