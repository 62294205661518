import React, { Fragment } from 'react'
import SearchRow from "../homepage/tabs/helpers/SearchRow";
import {BaseDropdownBtn} from "./BaseHamburgerBtn";
import {BtnDropdownToggleInlineShadowText} from "./dropdowns";
import { isPresent } from "../helpers/common";

const actualizedTitle = (display_name, active = false) =>
  <>
    <i className={`fa-solid fa-lock ${active ? 'text-white' : 'text-gray'} fa-sm ms-2 me-1`} />
    {display_name}
  </>

export default ({
                  selectedOption,
                  paddingClasses = 'ps-0 py-0',
                  searchQuery, setSearchQuery,
                  active = false, disabled_value = null,
                  options, onClick = (_) => null,
                  subKey = ''
                }) =>
  <BtnDropdownToggleInlineShadowText bsPrefix="text-dark fw-normal pe-0 h-auto"
                                     paddingClasses={paddingClasses}
                                     title={selectedOption || ''}
                                     align={'start'}>
    <div className="overflow-scroll" style={{maxHeight: 450}}>
      <div className="position-sticky top-0 bg-white" style={{padding: '4px 12px 4px 12px'}}>
        <SearchRow {...{ searchQuery, setSearchQuery }} bordered hideResults
                   rowStyles={'pb-1'} placeholder={'Search scenarios'}
                   resetSearchAction={() => setSearchQuery('')} />
      </div>
      <div className="dropdown-menu-scroll">
        {
          options.map(({ id, display_name, filtered_actualized, actualized, deleted_at }) =>
            <Fragment key={`${subKey}-${id}`}>
              <BaseDropdownBtn eventKey={id} title={display_name}
                               bsPrefix={id === active ? 'active' : ''}
                               onClick={() => onClick(id)}
                               disabled={isPresent(deleted_at) || disabled_value === id} />
              {
                (filtered_actualized || actualized || []).map(({ id, display_name }) =>
                  <BaseDropdownBtn key={`${subKey}-${id}`} eventKey={id}
                                   title={actualizedTitle(display_name, id === active)}
                                   onClick={() => onClick(id)}
                                   disabled={disabled_value === id}
                                   bsPrefix={`${id === active ? 'active' : ''}`} />
                )
              }
            </Fragment>

          )
        }
      </div>
    </div>

  </BtnDropdownToggleInlineShadowText>
