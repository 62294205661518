import Select from "react-select";
import {BorderedFilterStyle} from "../../tree_wizard/styles/choice_entry_styles";
import React from "react";
import { isPresent } from "../../helpers/common";

const SingleSelect = ({
                        field, concept,
                        disabled = false, attributes = {},
                        onSetAttribute = () => {}
                      }) => {
  return <div className="col-12 col-md-6 mb-3">
    <h4>{field.displayName}</h4>
    <Select onChange={(args) => onSetAttribute(args, concept, field)}
            components={{ IndicatorSeparator:() => null }}
            options={field.valuesOptions}
            value={field.valuesOptions.find(opt => opt.value === attributes[field.id])}
            defaultValue={
              field.valuesOptions.find(opt => opt.value === attributes[field.id]) || field.defaultOption
            }
            styles={BorderedFilterStyle}
            isSearchable isClearable
            isDisabled={disabled || concept.isDisabled}
            placeholder={'Any'} />
  </div>
}
const MultiSelect = ({
                       field, concept,
                       disabled = false, attributes = {},
                       onSetAttribute = () => {}
                     }) => {
  return <div className="col-12 mb-3">
    <h4>{field.displayName}</h4>
    <Select onChange={(args) => onSetAttribute(args, concept, field)}
            components={{ IndicatorSeparator:() => null }}
            options={field.valuesOptions}
            value={
              field.valuesOptions.filter(opt => attributes[field.id]?.includes(opt.value))
            }
            defaultValue={
              [
                field.valuesOptions.filter(opt => attributes[field.id]?.includes(opt.value)),
                [field.defaultOption].filter(isPresent)
              ].filter(isPresent)[0]
            }
            styles={BorderedFilterStyle}
            isSearchable isMulti isClearable
            isDisabled={disabled || concept.isDisabled}
            placeholder={'Any'} />
  </div>
}

export default ({attributes = {}, attributeFields = [], concept, disabled=false, onSetAttribute = () => {}}) => {
  const props = { attributes, concept, onSetAttribute, disabled }

  return <div className="row">
    {
      attributeFields.map(field => {
        if (field.isSingleSelect) {
          return <SingleSelect {...props} field={field} key={`attributes-${field.id}`} />
        } else if(field.isMultiSelect) {
          return <MultiSelect {...props} field={field} key={`attributes-${field.id}`} />
        }
      })
    }
  </div>
}
