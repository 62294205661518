import React from "react";
import {Table} from "react-bootstrap";
import CurrencyInput from "react-currency-input-field";
import { arrayLastElement } from "../../helpers/common";

export default ({
                  pkgFields, packages, concept,
                  onSetPackage = () => {},
                  setPackages = () => {},
                  disabled = false
                }) => {
  const addRow = () => {
    setPackages(prev => ({
      ...prev,
      [arrayLastElement(Object.keys(prev)) + 1]: {}
    }))
  };

  const removeRow = (key) => {
    setPackages(prev => {
      const newRows = { ...prev }
      delete newRows[key]
      return newRows;
    })
  };

  const AddIcon = ({onClick}) => {
    return <button onClick={onClick} className="btn btn-primary btn-sm-round h-36">
      <i className="fas fa-plus" />
    </button>
  }

  const TimesIcon = ({onClick}) => {
    return <button onClick={onClick} className="btn btn-light btn-sm-round h-36">
      <i className="fas fa-times" />
    </button>
  }

  return <Table borderless className="segmentation-table" style={{marginBottom: 18}}>
    <thead>
    <tr>
      {
        pkgFields.map((field, index) =>
          <th key={`package-field-${field.id}`}
              className={`fw-bolder ${index === 0 ? 'ps-0' : 'ps-1'} pt-0 ${index === 0 ? 'pe-1' : ''} pb-1`}>
            {field.displayName}
          </th>
        )
      }
      <th className="ps-1 pe-0 pt-0 pb-1"></th>
    </tr>
    </thead>
    <tbody>
    {
      Object.keys(packages).map((key, indx, collection) => {
        const pkg = packages[key]
        const isLastRow = indx === collection.length - 1
        return <tr key={`package-row-${key}`}>
          {
            pkgFields.map((field, index) =>
              <td key={`package-row-${key}-${field.id}`} className={`py-1 ${index === 0 ? 'pe-1' : 'px-1'}`}>
                {
                  field.isCurrency ?
                    <CurrencyInput
                      className="form-control rounded"
                      placeholder={field.placeholder}
                      decimalsLimit={2}
                      prefix={'$'}
                      allowNegativeValue={false}
                      maxLength={20}
                      decimalScale={2}
                      value={pkg[field.id]}
                      disabled={disabled || concept.isDisabled}
                      onValueChange={(enterValue) => {
                        let value = parseFloat(enterValue)
                        if (isNaN(value)) value = field.defaultValue

                        if (field.isPositive && value < 1) value = 1
                        onSetPackage(value, concept, field, key)
                      }}
                    /> :
                    <input type={ field.isNumber ? 'number' : 'text' }
                           className="form-control rounded"
                           value={pkg[field.id] || ''}
                           placeholder={field.placeholder}
                           min={field.isPositive ? 1 : null}
                           disabled={disabled || concept.isDisabled}
                           onChange={(e) => {
                             if (field.isNumber) {
                               let value = parseFloat(e.target.value.replace(/^0+(?=\d)/, "").replace(/\D/g, ""))
                               if (isNaN(value)) value = field.defaultValue
                               if (field.isPositive && value < 1) value = 1

                               onSetPackage(value, concept, field, key)
                             } else {
                               onSetPackage(e.target.value, concept, field, key)
                             }
                           }} />
                }
              </td>
            )
          }
          <td className="ps-1 py-1 text-center align-middle">
            {
              isLastRow ?
                  <AddIcon onClick={addRow} /> :
                  <TimesIcon onClick={() => removeRow(key)} />
            }
          </td>
        </tr>
      })
    }
    </tbody>
  </Table>
}
